.hero {
    position: relative;
}
.hero img {
    max-width: 1920px;
    width: 100%;
}
.hero .text {
    position: absolute;
    top: 50%;
    max-width: 39%;
    height: fit-height;

    background: linear-gradient(
        247.7deg,
        rgba(239, 239, 239, 0.45) 2.56%,
        rgba(255, 255, 255, 0.04) 91.2%
    );
    backdrop-filter: blur(25px);
    padding-right: 2rem;
    border-radius: 0 30px 30px 0;
}
.hero .text h2 {
    font-family: 'Mustard', cursive;
    font-style: normal;
    font-size: 40px;
    font-weight: bold;
    line-height: 50px;
    color: #fff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 40px 60px 30px 6rem;
}
.user-action {
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.25) 0%,
        rgba(255, 255, 255, 0) 100%
    );
    backdrop-filter: blur(50px);

    position: absolute;
    top: 0;
    right: 100px;
    width: 150px;
    height: 50px;
}
.user-action h2 {
    font-family: 'Mustard', sans-serif;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    padding-top: 1.75rem;
    letter-spacing: 0.05em;
    color: #fff;
    cursor: pointer;
}
.take-assessment {
    background: #9b3fe7;
    border-radius: 40px;
    font-family: 'Mustard', cursive;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: -0.015em;
    color: #fff;
    border: 0.5px solid #fff;
    padding: 8px 50px;
    position: absolute;
    bottom: 3rem;
    left: 6rem;
    cursor: pointer;
}

/* // */
/* // */
.section-2 {
    margin: auto;
    padding: 6rem;
    display: flex;
    justify-content: space-between;
}
.section-2 h2 {
    font-family: 'Montserrat';
    font-weight: 900;
    font-size: 64px;
    line-height: 50px;
    letter-spacing: -0.015em;
    color: #9b3fe7;
}
.section-2 p {
    /* width: 75%; */
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
}
.section-2 h3 {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 18px;
    line-height: 60px;
    text-align: right;
    margin-left: 2rem;
}

.section-2 .text {
    width: 55%;
}

.section-2 img {
    border-radius: 20px;
}
.section-2 {
    position: relative;
}

.section-2 .avatar .avatar-1 {
    position: absolute;
    bottom: 50px;
    right: 25px;
    width: 15%;
}
.section-2 .avatar .avatar-2 {
    position: absolute;
    right: 40px;
    top: 20px;
}
.section-2 .avatar .avatar-3 {
    position: absolute;
    left: 12rem;
    top: 20px;
}
.section-2 .avatar .avatar-4 {
    position: absolute;
    left: 3rem;
    bottom: 0;
}
.section-3 {
    width: 100%;
    height: 250px;
    background: #f1fcfc;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.section-3 div {
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.section-3 div h2 {
    max-width: 55%;

    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: rgba(0, 0, 0, 0.8);
}
.section-3 .buttons {
    width: 40%;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-around;
}
.section-3 div .section-3 div a {
    font-size: 15px;
}
.section-3 div a:nth-of-type(1) {
    background: #9b3fe7;
    border-radius: 35px;
    color: #fff;
    font-weight: 800;
    padding: 1rem 2rem;
}
.section-3 div a:nth-of-type(2) {
    color: #9b3fe7;
    font-weight: 800;
    padding: 1rem 2rem;
    background: #fff;
    border: 1px solid #9b3fe7;
    box-sizing: border-box;
    border-radius: 35px;
}
.sign {
    overflow: hidden;
    position: absolute;
    z-index: 10;
    width: 90%;
    height: 100vh;
    top: 0;
    right: 0;
}
.sign .sign-blur {
    top: 0;

    border-radius: 30px;

    background: linear-gradient(
        85.52deg,
        rgba(239, 239, 239, 0.9) -13.19%,
        rgba(255, 255, 255, 0.08) 103.43%
    );
    backdrop-filter: blur(95px);

    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
}
.sign .container {
    z-index: 2;
    width: 80%;
    padding: 5rem 7.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.sign .container .text {
    width: 50%;
}
.sign .container .text h2 {
    font-family: 'Agency FB';
    font-weight: 700;
    font-size: 40px;
    line-height: 55px;
    letter-spacing: -0.015em;
    color: #000;
}
.sign .container .text p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.8);
    padding: 30px 0 40px;
}
.sign .container .text .statics {
    display: flex;
    justify-content: space-between;
    width: 70%;
}
.sign .container .text .statics h4 {
    font-family: 'Agency FB';
    font-weight: 700;
    font-size: 40px;
    line-height: 55px;
    letter-spacing: -0.015em;
    color: #000;
}
.sign .container .text .statics h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.8);
}
.sign .form-container {
    width: 38%;
    background: linear-gradient(
        85.52deg,
        rgba(239, 239, 239, 0.9) -13.19%,
        rgba(255, 255, 255, 0.08) 103.43%
    );
    backdrop-filter: blur(95px);

    border-radius: 33px;
    display: flex;
    align-items: center;
    text-align: center;
}
.sign .form-container .form {
    padding: 4rem 3rem;
}
.sign .form-container .form p {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.015em;
    color: #000;
    padding-bottom: 1rem;
}
.sign .form-container .form h2 {
    font-family: 'Mustard';
    font-weight: 700;
    font-size: 48px;
    letter-spacing: -0.015em;
    color: #000000;
    padding: 1.5rem 0 2rem;
}
.sign .form-container .form input {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    width: 100%;
    margin-top: 15px;
    font-size: 14px;
    border-radius: 33px;
}
.sign .form-container .form button {
    padding-top: 1rem;
    background-color: transparent;
    border-bottom: 3px solid #000000;
    font-family: 'Mustard';
    font-weight: 700;
    font-size: 18px;
    line-height: 35px;
    text-align: center;
    color: #000000;
}
.sign .form-container .other-action {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
}
.sign .form-container .other-action a {
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(51, 51, 51, 0.15);
    border-radius: 33px;
    color: #9b3fe7;
    padding: 0.75rem 2rem;
    margin-bottom: 1.5rem;
}
.hide {
    display: none;
}
@media only screen and (max-width: 1200px) {
    .sign .form-container {
        width: 37%;
    }
}
@media only screen and (max-width: 1100px) {
    .sign .form-container {
        width: 45%;
    }
}

@media only screen and (max-width: 600px) {
    .sign {
        /* display: none; */
    }

    .hero img {
        height: 70vh;
        width: 105vw;
        object-fit: cover;
    }
    .hero .text {
        width: 80%;
        max-width: 80%;
    }
    .hero .text h2 {
        font-size: 25px;
        line-height: 30px;
        font-weight: 100;
        padding: 20px 60px 30px 40px;
    }
    .user-action {
        right: 0px;
    }
    .take-assessment {
        font-weight: 100;
        font-size: 13px;
        padding: 6px 40px;
        left: 2rem;
        bottom: 1rem;
    }
    .section-2 {
        padding: 2rem;
        flex-direction: column;
        align-items: center;
    }
    .section-2 h2 {
        font-size: 50px;
        line-height: 30px;
    }
    .section-2 .text {
        width: 100%;
    }
    .section-2 p {
        width: 100%;
        font-size: 15px;
        font-weight: 500;
        line-height: 21px;
        padding: 0px 0.5rem;
    }
    .section-2 h3 {
        width: 100%;
        font-size: 12px;
        font-weight: 800;
        line-height: 125%;
        text-align: right;
        margin: 1rem;
    }
    .section-2 img {
        width: 300px;
        margin-top: 10%;
        border-radius: 15px;
    }
    .section-2 .avatar .avatar-1 {
        width: 35%;
        left: 2%;
        bottom: 0%;
    }
    .section-2 .avatar .avatar-2 {
        width: 25%;
        right: 4%;
        top: 45%;
    }
    .section-2 .avatar .avatar-3 {
        width: 75px;
        top: -11%;
        left: 77%;
    }
    .section-2 .avatar .avatar-4 {
        width: 100px;
        display: none;
    }
    .section-3 {
        height: 100px;
        padding-bottom: 2rem;
    }

    .section-3 div {
        width: 100%;
    }

    .section-3 div {
        flex-direction: column;
    }
    .section-3 div h2 {
        max-width: 80%;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        margin-bottom: 2rem;
    }
    .section-3 .buttons {
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .section-3 div a {
        text-align: center;
        margin-bottom: 10px;
    }

    .section-3 div a:nth-of-type(1) {
        padding: 0.75rem 0.7rem !important;
        font-size: 11px;
        width: 150px;
    }
    .section-3 div a:nth-of-type(2) {
        width: 150px;
        padding: 0.75rem 0.7rem !important;
        width: 100%;
        font-size: 11px;
    }
    .user-action {
        width: 123px;
        height: 49px;
        margin-right: 1rem;
    }
}

.login-sub-conatiner {
    background-color: white;
    max-width: 391.3px;
    padding: 10rem 1rem;
    background: linear-gradient(
        85.52deg,
        rgba(239, 239, 239, 0.9) -13.19%,
        rgba(255, 255, 255, 0.08) 103.43%
    );
    -webkit-backdrop-filter: blur(95px);
    backdrop-filter: blur(95px);
    border-radius: 33px;
}
