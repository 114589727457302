/* THE CARD HOLDS THE FRONT AND BACK FACES */
.thecard {
  transform-style: preserve-3d;
  transition: all 0.8s ease;
}

/* THE PSUEDO CLASS CONTROLS THE FLIP ON MOUSEOVER AND MOUSEOUT */
#card-active {
  transform: rotateY(180deg);
}

/* THE FRONT FACE OF THE CARD, WHICH SHOWS BY DEFAULT */
.thefront {
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  overflow: hidden;
}

/* THE BACK FACE OF THE CARD, WHICH SHOWS ON MOUSEOVER */
.theback {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  overflow: hidden;
  transform: rotateY(180deg);
}

.avatar-8 {
  position: absolute;
  width: 8%;
  top: 325%;
}
.avatar-3 {
  position: absolute;
  width: 6%;
  left: 2%;
  top: 125%;
}
.avatar-4 {
  position: absolute;
  width: 6%;
  left: 2%;
  top: 170%;
}
.avatar-9 {
  position: absolute;
  width: 6%;
  left: 10%;
  top: 230%;
}
.avatar-7 {
  position: absolute;
  width: 6%;
  top: 270%;
  right: 0;
}

.section-3 {
  width: 100%;
  height: 250px !important;
  background: #f1fcfc;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.section-3 div {
  width: 88%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.section-3 div h2 {
  max-width: 58%;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: rgba(0, 0, 0, 0.8);
}
.section-3 .button {
  width: 40%;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-around;
}
.section-3 div a {
  font-size: 13px;
}
.section-3 div a:nth-of-type(1) {
  background: #9b3fe7;
  border-radius: 35px;
  color: #fff;
  font-weight: 500;
  padding: 1rem 2rem;
}
.section-3 div a:nth-of-type(2) {
  color: #9b3fe7;
  font-weight: 500;
  padding: 1rem 2rem;
  background: #fff;
  border: 1px solid #9b3fe7;
  box-sizing: border-box;
  border-radius: 35px;
}

@media only screen and (max-width: 600px) {
  .section-3 div {
    width: 90%;
    height: 100px;
    display: flex;
    align-items: center;
  }
  .section-3 div h2 {
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
  }
  .section-3 .button {
    text-align: center;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .section-3 div a:nth-of-type(1) {
    font-size: 13px;
    padding: 0.5rem 0.7rem;
    margin-bottom: 10px;
  }
  .section-3 .button2 {
    display: none;
  }
  .avatar-8 {
    width: 10%;
    top: 250%;
    right: 2%;
  }
  .avatar-9 {
    width: 10%;
    top: 190%;
    left: 85%;
  }
  .avatar-7 {
    width: 10%;
    top: 84%;
    left: 80%;
  }
  .avatar-3 {
    width: 10%;
    top: 103%;
    left: 5%;
  }
}

.text-thankyou {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

.text-thankyou h1 {
  font-size: 2rem;
  color: white;
}
.text-thankyou h2 {
  font-size: 1rem;
  font-weight: 400;
  margin-top: 0.5rem;
  color: white;
}
