/***********end of sign up**********/
/*  */
/*  */
/*  */

.main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.report .hero {
  position: relative;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 480px) {
  .report .hero img {
    height: 100vh;
    z-index: 200;
  }
}
.report .hero img {
  width: 100%;
  z-index: 200;
}
.report .hero .ball {
  position: absolute;
  z-index: -1;
  top: 0;
  display: flex;
}
.report .hero .report-text {
  position: absolute;
  bottom: 0;
  text-align: center;
}

.report .hero .report-text h3 {
  padding-bottom: 3rem;
  font-weight: 400;
  font-family: 'Mustard';
  font-size: 25px;
  letter-spacing: -0.015em;
  font-weight: bold;
}
@media screen and (max-width: 480px) {
  .report .hero .report-text h3 {
    font-family: 'Montserrat';
    padding: 2rem 2rem;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
  }
}
.report .card-stack {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.report .card-stack img {
  width: 70%;
  padding: 2rem 0;
}
.report .card-stack h4 {
  font-family: 'Mustard';
  font-weight: 700;
  font-size: 48px;
  line-height: 80px;
  letter-spacing: -0.015em;
  color: #000;
  margin-bottom: -3rem;
  margin-top: 4rem;
}
.report h6 {
  font-family: 'Mustard';
  font-weight: 700;
  font-size: 21px;
  line-height: 80px;
  letter-spacing: -0.015em;
  color: #000;
  text-align: center;
  margin-bottom: 5rem;
  margin-top: -10rem;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .report .card-stack img {
    width: 90%;
  }
  .report .card-stack h4,
  .report h6 {
    margin: 0;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #000;
  }
}
.report .card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 2rem 1rem;
}
.report .card img {
  cursor: pointer;
}

@media screen and (min-width: 480px) {
  .report .card .main {
    margin-top: 5rem;
    display: flex;
  }
  .report .card .main .d-card:nth-of-type(1) {
    transform: rotate(9deg);
  }
  .report .card .main .d-card:nth-of-type(2) {
    padding-top: 2rem;
  }
  .report .card .main .d-card:nth-of-type(3) {
    transform: rotate(-9deg);
    margin-bottom: 9rem;
  }
  .report .card .main .thecard {
    margin: 2rem 4rem;
  }
}
.report .card .other {
  display: flex;
  flex-direction: column;
}
.report .card .other img {
  width: 48px;
  height: 64px;
  border-radius: 5px;
  margin: 1rem 0;
}
.report .your-traits {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5rem 0;
}
.report .your-traits .parent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.report .your-traits .parent div img {
  width: 115px;
}
.report .your-traits .div1 {
  grid-area: 1/1/2/2;
}
.report .your-traits .div2 {
  grid-area: 1/2/3/3;
}
.report .your-traits .div3 {
  grid-area: 1/3/2/4;
}
.report .your-traits .div4 {
  grid-area: 2/1/3/2;
}
.report .your-traits .div5 {
  grid-area: 2/3/3/4;
}
.report .your-traits .div6 {
  grid-area: 3/1/4/2;
}
.report .your-traits .div7 {
  grid-area: 3/2/4/3;
}
.report .your-traits .div8 {
  grid-area: 3/3/4/4;
}
.your-traits-desktop {
  margin-bottom: 5rem;
}
.report .your-traits-desktop .parent {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.report .your-traits-desktop .parent div {
  cursor: pointer;
}
.report .your-traits-desktop .parent div img {
  width: 175px;
  height: 100%;
  max-height: 250px;
}
.report .your-traits-desktop .trait-animation {
  position: relative;
  /* border: 5px solid #8850e2; */
  width: 80%;
  height: 250px;
}
.cancel-Icon {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.25rem;
  cursor: pointer;
  color: #d7d7d7;
}
.cancel-Icon:hover {
  color: #8850e2;
}
.report .your-traits-desktop .trait-animation img {
  max-height: 100%;
  max-width: 22%;
  width: auto;
  /* background: linear-gradient(to right, #c399ed 0%, #ffffff 95%, #ffffff 100%); */
}
.report .your-traits-desktop .trait-animation .bar {
  position: absolute;
  top: 80%;
  left: 25%;
  width: 78%;
}
.report .your-traits-desktop .trait-animation .bar .bar-skills {
  position: absolute;
  top: 25%;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: space-around;
}
.report .your-traits-desktop .trait-animation .bar .bar-skills p {
  font-family: 'Mustard';
  font-size: 12px;
  font-weight: 500;
  width: 15%;
  line-height: 145%;
}
.bar-skill {
  padding: 7px 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bar-skill:nth-of-type(1) {
  background: #dbe64f;
  border-radius: 50px 0 0 50px;
}
.bar-skill:nth-of-type(2) {
  background: #dbe64fb2;
}
.bar-skill:nth-of-type(3) {
  background: #dbe64f66;
}
.bar-skill:nth-of-type(4) {
  background: #d6632c66;
}
.bar-skill:nth-of-type(5) {
  background: #d6632cb2;
}
.bar-skill:nth-of-type(6) {
  background: #d6632c;
  border-radius: 0 50px 50px 0;
}

.individual-card {
  display: flex;
  position: relative;
}
.individual-card .background img {
  width: 280px;
  height: 390px;
  border-radius: 10px;
}
.individual-card .individual-card-body {
  position: absolute;
  z-index: 100;
}
.individual-card .individual-card-body .avatar {
  width: 220px;
  max-height: 140px;
  object-fit: contain;
  padding: 2rem 2rem 0.5rem;
}
.individual-card .individual-card-body .text {
  margin: 0 1rem;
}
.individual-card .individual-card-body h2 {
  font-family: 'Mustard';

  font-weight: 700;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  color: #fff;
}
.individual-card .individual-card-body p,
.individual-card .individual-card-body p span {
  font-family: 'Montserrat';
  font-size: 11px;
  line-height: 15px;
  font-weight: 500;
  color: #fff;
  /* padding-bottom: 5px; */
}
.individual-card .individual-card-body p span {
  line-height: 17px;
}

.individual-card .individual-card-body .sm span {
  font-size: 10px !important;
}
.individual-card .individual-card-body p span {
  font-weight: 800;
}
.individual-card .individual-card-body .skill {
  padding-top: 1rem;
  display: flex;
}
.individual-card .individual-card-body .skill .sub-text {
  width: 75%;
  padding-left: 1rem;
}
.individual-card .individual-card-body .skill .sub-text h2 {
  font-family: 'arial';
  text-align: left;
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  padding-bottom: 10px;

  color: #fff;
}
.thecard {
  width: 280px;
  height: 390px;
  border-radius: 10px;
}
.thefront img {
  width: 280px;
  height: 390px;
  border-radius: 10px;
}
.CircularProgressbar .CircularProgressbar-trail {
  stroke: #878787 !important;
}
.CircularProgressbar .CircularProgressbar-path {
  stroke: #fff !important;
}
.CircularProgressbar .CircularProgressbar-text {
  fill: #fff !important;
  font-size: 24px;
  font-weight: bold;
}

.traits-detail {
  /* position: absolute; */
  display: flex;
  justify-content: center;
  height: 250px;
}
.traits-detail .text {
  position: absolute;
  top: 15%;
  left: 25%;
  width: 78%;
  line-height: 30px;
}

.report-loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-traits {
  width: 115px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 0.5rem;
}

.mobile-traits h2 {
  font-family: 'Montserrat';
  font-size: 7.5px;
  line-height: 10px;
  color: #000000;
  text-align: center;
  margin-bottom: 0.5rem;
}

.mobile-traits .mobile-traits-mobile {
  width: 80%;
  border-radius: 25px;
  padding: 5px;
  margin: 0 0.5rem;
  margin-bottom: 5px;
}
.mobile-traits .mobile-traits-mobile p {
  font-family: 'Montserrat';
  font-size: 8px !important;
  font-weight: bold;

  line-height: 10px;
  text-align: center;
}

.mobile-traits .mobile-traits-mobile:nth-of-type(1) {
  background: #dbe64f;
}
.mobile-traits .mobile-traits-mobile:nth-of-type(2) {
  background: #dbe64fb2;
}
.mobile-traits .mobile-traits-mobile:nth-of-type(3) {
  background: #dbe64f66;
}
.mobile-traits .mobile-traits-mobile:nth-of-type(4) {
  background: #d6632c66;
}
.mobile-traits .mobile-traits-mobile:nth-of-type(5) {
  background: #d6632cb2;
}
.mobile-traits .mobile-traits-mobile:nth-of-type(6) {
  background: #d6632c;
}

.mobile-traits-non-open {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 270px;
}
.mobile-traits-non-open h2 {
  font-family: 'Mustard';
  font-weight: 700;
  font-size: 13px;
  line-height: 21px;
  text-align: center;
  color: #000000;
}
.mobile-traits-non-open p {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #000000;
}

.m-y-t .parent {
  width: 352px;
}
.m-y-t .parent div img {
  height: 140px;
}

.card-3-text p {
  padding-bottom: 4px !important;
}

.your-traits-desktop {
  position: relative;
}

.your-traits-desktop .traits-detail {
  z-index: 100;
}

.plan-1 {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100%;
  background: linear-gradient(
    85.52deg,
    rgba(239, 239, 239, 0.9) -13.19%,
    rgba(255, 255, 255, 0.08) 103.43%
  );
  backdrop-filter: blur(95px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.plan-1 .container {
  min-width: 300px;
  width: fit-content;
  max-width: 700px;
  background: linear-gradient(
    85.52deg,
    rgba(239, 239, 239, 0.9) -13.19%,
    rgba(255, 255, 255, 0.08) 103.43%
  );
  backdrop-filter: blur(95px);
  border-radius: 1rem;
  padding: 2rem;
}

.plan-1 .container h2 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #000;
  margin-bottom: 1rem;
}

.plan-1 .plan-1-form {
  display: flex;
  flex-direction: column;
}

.plan-1 .plan-1-form select,
.plan-1 .plan-1-form input,
.plan-1 .plan-1-form button {
  padding: 0.75rem 1.5rem;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  background-color: white;
  border: none;
  margin: 1rem 0;
  font-weight: 500;
}

.space-10px {
  height: 10px;
  display: block;
}
