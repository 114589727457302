.calendar-container .heading-calendar {
    font-weight: 500;
    margin: 3% 5%;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.calendar-container .left-text {
    display: flex;
    justify-content: center;
    font-size: 1.5em;
}

.calendar-container .right-text {
    display: flex;
    justify-content: center;
    font-size: 1.5em;
}

.calendar-container .calendar {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0% 5%;
}

.calendar-container .grid-2 {
    display: grid;
    grid-template-columns: auto auto;
}

.calendar-container .grid-3 {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin: 1rem;
}

.calendar-container .date-text-1 {
    margin-bottom: 3%;
}

.calendar-container .date-text-2 {
    margin-bottom: 8%;
    font-size: 0.8em;
}

.calendar-container .simple-text {
    border: 1px solid black;
    margin: 0 1em 1em 0;
    padding: 1em 2em;
    text-align: center;
}

.calendar-container .simple-text-2 {
    border: 1px solid black;
    margin-bottom: 1rem;
    padding: 1em;
    text-align: center;
}

.calendar-container .active-text {
    border: 1px solid black;
    margin: 0 1em 1em 0;
    padding: 1em 2em;
    text-align: center;
    background-color: #006edc;
    color: white;
}

.calendar-container .active-text-2 {
    border: 1px solid black;
    margin: 0 0 1rem;
    padding: 1em 2em;
    text-align: center;
    background-color: #006edc;
}
.calendar-container .active-text-2 p {
    color: white;
}

.calendar-container .active-text:hover {
    cursor: pointer;
}

.calendar-container .active-text-2:hover {
    cursor: pointer;
}

.calendar-container .simple-text:hover {
    cursor: pointer;
}

.calendar-container .simple-text-2:hover {
    cursor: pointer;
}

.calendar-container .booking-text-plan2 {
    font-weight: 500;
    padding-bottom: 5%;
    text-align: center;
    line-height: 150%;
}

.calendar-container .booking-text {
    font-weight: 500;
    padding-bottom: 5%;
    padding-top: 6%;
}

.heading-simple-text {
    font-weight: 500;
    padding-bottom: 5%;
}

/* MEDIA QUERIES */
@media screen and (max-width: 1450px) {
    .calendar-container .grid-1 {
        transform: scale(0.9);
        margin: -6.5% 0 0 -5.1%;
    }
    .calendar-container .date-text-2 {
        margin-right: 20%;
        font-size: 0.8em;
    }
}

@media screen and (max-width: 1400px) {
    .calendar-container .grid-2-outer {
        margin-left: 5%;
    }
    .calendar-container .date-text-1 {
        margin-left: 5%;
    }
    .calendar-container .date-text-2 {
        margin-left: 5%;
    }
    .calendar-container .date-text-2 {
        margin-right: 20%;
        font-size: 0.8em;
    }
}

@media screen and (max-width: 1300px) {
    .calendar-container .grid-1 {
        transform: scale(0.8);
        margin: -15.5% 0 0 -11%;
    }
    .calendar-container .date-text-2 {
        margin-right: 20%;
        font-size: 0.8em;
    }
}

@media screen and (max-width: 1150px) {
    .calendar-container .grid-1 {
        transform: scale(0.8);
        margin: -31.5% 0 0 -11%;
    }
    .calendar-container .grid-2 {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .calendar-container .date-text-2 {
        margin-right: 20%;
        font-size: 0.8em;
    }
}

@media screen and (max-width: 1100px) {
    .right-text {
        display: flex;
        justify-content: center;
        font-size: 1.5em;
    }
    .calendar-container .date-text-2 {
        margin-right: 20%;
        font-size: 0.8em;
    }
}

@media screen and (max-width: 1000px) {
    .calendar-container .grid-2 {
        margin-right: 8%;
    }
    .calendar-container .date-text-1 {
        margin-right: 8%;
    }
    .calendar-container .date-text-2 {
        margin-right: 8%;
    }
    .calendar-container .date-text-2 {
        margin-right: 20%;
        font-size: 0.8em;
    }
}

@media screen and (max-width: 900px) {
    .calendar-container .grid-1 {
        margin: -28% 0 0 -11%;
    }
    .calendar-container .grid-3 {
        margin: 5% -50% 15% 50%;
    }
    .calendar-container .booking-text {
        display: flex;
        justify-content: center;
    }
    .calendar-container .calendar {
        grid-template-columns: 1fr 1fr;
    }
    .calendar-container .date-text-2 {
        margin-right: 20%;
        font-size: 0.8em;
    }
}

@media screen and (max-width: 850px) {
    .calendar-container .grid-1 {
        margin: -29% 0 0 -11%;
    }
    .calendar-container .grid-3 {
        margin: 5% -50% 15% 50%;
    }
    .calendar-container .booking-text {
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 800px) {
    .calendar-container .grid-1 {
        margin: -31% 0 0 -11%;
    }
    .calendar-container .grid-3 {
        margin: 5% -50% 15% 50%;
    }
    .calendar-container .booking-text {
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 750px) {
    .calendar-container .grid-3 {
        margin: 5% -40% 15% 60%;
    }
}

@media screen and (max-width: 700px) {
    .calendar-container .grid-1 {
        margin: -31% 0 0 -11%;
    }
    .calendar-container .grid-3 {
        margin: 5% -30% 15% 60%;
    }
    .calendar-container .booking-text {
        display: flex;
        justify-content: center;
    }
    .calendar-container .heading-calendar-calendar {
        font-size: 0.9em;
    }
    .calendar-container .date-text-2 {
        margin-right: 20%;
        font-size: 0.8em;
    }
}

@media screen and (max-width: 650px) {
    .calendar-container .grid-3 {
        margin: 5% -20% 15% 60%;
    }
}

@media screen and (max-width: 600px) {
    .calendar-container .calendar {
        grid-template-columns: 1fr;
    }
    .calendar-container .grid-1 {
        transform: scale(1);
        margin: -2% 0 5% 0;
        display: flex;
        justify-content: center;
    }
    .calendar-container .grid-2-outer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: -10%;
        margin-left: 2%;
    }
    .calendar-container .grid-3 {
        margin: 5% 2% 10% 2%;
    }
    .calendar-container .booking-text {
        display: flex;
        justify-content: center;
    }
    .calendar-container .date-text-1 {
        display: flex;
        justify-content: center;
    }
    .calendar-container .date-text-2 {
        display: flex;
        justify-content: center;
    }
    .heading-calendar {
        font-size: 0.8em;
    }
    .calendar-container .date-text-2 {
        margin-right: 20%;
        font-size: 0.8em;
    }
}

@media screen and (max-width: 550px) {
    .calendar-container .heading-calendar {
        margin: 3% 5%;
        grid-template-columns: 1fr;
    }
    .calendar-container .left-text {
        margin-bottom: 2%;
    }
    .calendar-container .date-text-2 {
        margin-right: 20%;
        font-size: 0.8em;
    }
}

@media screen and (max-width: 450px) {
    .calendar-container .heading-calendar {
        font-size: 0.65em;
    }
    .calendar-container .grid-1 {
        transform: scale(0.9);
        margin-top: -5%;
        margin-bottom: 4%;
    }
    .calendar-container .date-text-2 {
        margin-right: 20%;
        font-size: 0.8em;
    }
}

@media screen and (max-width: 400px) {
    .calendar-container .heading-calendar {
        font-size: 0.65em;
    }
    .calendar-container .grid-1 {
        transform: scale(0.8);
        margin-top: -10%;
        margin-bottom: 0;
    }
    .calendar-container .grid-2-outer {
        margin-right: -8%;
        margin-left: 4%;
    }
    .calendar-container .grid-3 {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 5% 5% 10% 4%;
    }
    .calendar-container .date-text-2 {
        margin-right: 20%;
        font-size: 0.8em;
    }
}

@media screen and (max-width: 370px) {
    .calendar-container .grid-1 {
        transform: scale(0.7);
        margin-top: -16%;
        margin-bottom: -6%;
        margin-left: -8%;
    }
    .calendar-container .grid-2-outer {
        margin-right: 0;
        margin-left: 5%;
    }
    .calendar-container .grid-3 {
        margin: 5% 12.5% 10% 5%;
    }
    .calendar-container .date-text-2 {
        margin-right: 20%;
        font-size: 0.8em;
    }
}

@media screen and (max-width: 350px) {
    .calendar-container .grid-1 {
        transform: scale(0.6);
        margin-top: -21%;
        margin-bottom: -10%;
        margin-left: -16%;
    }
    .calendar-container .grid-2-outer {
        margin-right: 5%;
        margin-left: 6%;
    }
    .calendar-container .grid-3 {
        margin-right: 17%;
        margin-left: 6%;
    }
    .calendar-container .date-text-1 {
        margin-right: 20%;
    }
    .calendar-container .date-text-2 {
        margin-right: 20%;
        font-size: 0.8em;
    }
}

@media screen and (max-width: 330px) {
    .calendar-container .grid-1 {
        transform: scale(0.6);
        margin-top: -21%;
        margin-bottom: -10%;
        margin-left: -16%;
    }
    .calendar-container .grid-2-outer {
        margin-right: 11%;
        margin-left: 6%;
    }
    .calendar-container .grid-3 {
        margin-right: 22%;
        margin-left: 6%;
    }
    .calendar-container .date-text-1 {
        margin-right: 22%;
    }
    .calendar-container .date-text-2 {
        margin-right: 25%;
        font-size: 0.8em;
    }
}

@media screen and (max-width: 300px) {
    .calendar-container .grid-1 {
        transform: scale(0.55);
        margin-top: -22%;
        margin-bottom: -12%;
        margin-left: -24%;
    }
    .calendar-container .grid-2-outer {
        margin-right: 19%;
        margin-left: 6%;
    }
    .calendar-container .grid-3 {
        margin-right: 29.5%;
        margin-left: 6%;
    }
    .calendar-container .date-text-1 {
        margin-right: 25%;
    }
    .calendar-container .date-text-2 {
        margin-right: 29%;
        font-size: 0.8em;
    }
}

@media screen and (max-width: 270px) {
    .calendar-container .grid-1 {
        transform: scale(0.55);
        margin-top: -22%;
        margin-bottom: -12%;
        margin-left: -30%;
    }
    .calendar-container .grid-2-outer {
        margin-right: 27%;
        margin-left: 6%;
    }
    .calendar-container .grid-3 {
        margin-right: 37%;
        margin-left: 6%;
    }
    .calendar-container .date-text-1 {
        margin-right: 32%;
    }
    .calendar-container .date-text-2 {
        margin-right: 34%;
        font-size: 0.8em;
    }
}

@media screen and (max-width: 240px) {
    .calendar-container .grid-1 {
        transform: scale(0.4);
        margin-top: -31%;
        margin-bottom: -20%;
        margin-left: -39%;
    }
    .calendar-container .grid-2-outer {
        margin-right: 35%;
        margin-left: 6%;
    }
    .calendar-container .grid-3 {
        margin-right: 44%;
        margin-left: 6%;
    }
    .calendar-container .date-text-1 {
        margin-right: 40%;
    }
    .calendar-container .date-text-2 {
        margin-right: 44%;
        font-size: 0.8em;
    }
}

@media screen and (max-width: 210px) {
    .calendar-container .grid-1 {
        transform: scale(0.4);
        margin-top: -31%;
        margin-bottom: -20%;
        margin-left: -45%;
    }
    .calendar-container .grid-2-outer {
        margin-right: 40%;
        margin-left: 4%;
    }
    .calendar-container .grid-3 {
        margin-right: 48%;
        margin-left: 4%;
    }
    .calendar-container .date-text-1 {
        margin-right: 48%;
    }
    .calendar-container .date-text-2 {
        margin-left: 2%;
        margin-right: 46%;
        font-size: 0.8em;
    }
}

.PaymentButton-contents span,
.PaymentButton-contents div{
    color: white;
}