@media screen and (max-width: 480px) {
    .payment-background {
        filter: blur(30px);
    }
}
.payment .packages {
    /* width: 100%; */
    /* margin: 3em; */
    /* overflow: hidden; */
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    margin-top: 3em;
    margin-left: 2em;
    margin-right: 1em;
/* 
    width: 100%;
    height: 100%; */
}

@media screen and (min-width: 480px) {
    .payment .packages {
        flex-direction: row;
        justify-content: space-around;
        /* height: 94vh; */
    }
}
.payment .packages .package {
    /* width: 375px; */
    /* flex-basis:  1/3; */
    flex: 1;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
    width: 0; /* remove scrollbar space */
    /* background-color: transparent; */
    /* overflow-y: scroll; */
    /* background: linear-gradient(
        85.52deg,
        rgba(239, 239, 239, 0.9) -13.19%,
        rgba(255, 255, 255, 0.08) 103.43%
    ); */
    background: rgb(219, 221, 214);
    /* -webkit-backdrop-filter: blur(85px);
    backdrop-filter: blur(85px); */
    border-radius: 27.6895px;
    /* border-color: rgb(211, 31, 31);
    border-width: 2px; */
}
@media screen and (max-width: 480px) {
    .payment .packages .package {
        width: 340px;
    }
}
.payment .packages .package .package-name {
    margin-top: 3rem;
    width: 100%;
    height: 100%;
    background: radial-gradient(
        75.22% 328.39% at 69.64% 50.59%,
        #9b3fe7 0%,
        #26a9e0 100%
    );
    display: flex;
    justify-content: center;
    align-items: center;
}
.payment .packages .package .package-name h4 {
    font-family: 'Mustard';
    font-weight: 700;
    font-size: 25px;
    line-height: 50px;
    color: #fff;
}
@media screen and (max-width: 480px) {
    .payment .packages .package .package-name {
        display: none;
    }
}
.payment .packages .package .package-detail {
    margin: 0 1.5rem;
}
.payment .packages .package .package-detail h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #000;
    margin: 2rem 0;
}
@media screen and (max-width: 480px) {
    .payment .packages .package .package-detail h2 {
        font-size: 20px;
        margin: 0;
        padding: 2rem 0 1rem;
    }
}
.payment .packages .package .package-detail p {
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
    font-family: 'Montserrat';
    font-size: 14px;
    line-height: 18px;
    color: #000;
}
.payment .packages .package .package-detail p span {
    padding-right: 1rem;
}
.payment .packages .package .package-detail p span img {
    height: 35px;
}
.payment .packages .package .package-action {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
    /* margin: 2rem; */
    padding: 2rem;
   /* margin-bottom: 1rem; 
   margin-top: 2rem; */
}
.payment .packages .package .package-action h4 {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    color: #000;
    margin: 2rem; 
}

.payment .packages .package  .package-action-button {
    flex: 1;
}

@media screen and (max-width: 480px) {
    .payment .packages .package .package-action h3 {
        font-size: 18px;
    }
}
.payment .packages .package .package-action button {
    background: #fff;
    border-radius: 20px;
    font-family: 'Mustard';
    font-weight: 700;
    font-size: 17px;
    color: #000;
    padding: 10px 25px;
}

.payment .packages .package .package-action .button {
    background: #fff;
    border-radius: 20px;
    font-family: 'Mustard';
    font-weight: 700;
    font-size: 17px;
    color: #000;
    padding: 10px 25px;
}

@media screen and (max-width: 480px) {
    .payment .packages .package .package-action a {
        font-size: 14px;
    }
}
.payment .mobile-package-tabs {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 1rem 0 2rem;
    width: 100%;
}
.payment .mobile-package-tabs p {
    font-family: 'Mustard';
    font-weight: 700;
    font-size: 12px;
    color: #000;
    background-color: #fff;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
}
@media screen and (min-width: 480px) {
    .payment .mobile-package-tabs {
        display: none;
    }
}
.payment .tag-line {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000;
    font-size: 22px;
    margin-bottom: 4rem;
    margin-top: 4rem;
}
@media screen and (max-width: 480px) {
    .payment .tag-line {
        margin-top: 2rem;
    }
}
.payment .payment-page-flag {
    background: linear-gradient(
        85.52deg,
        rgba(239, 239, 239, 0.9) -13.19%,
        rgba(255, 255, 255, 0.08) 103.43%
    );
    box-shadow: 4px 4px 30px rgba(51, 51, 51, 0.25);
    -webkit-backdrop-filter: blur(85px);
    backdrop-filter: blur(85px);
    margin: 3rem 0;
}
.payment .payment-page-flag h4 {
    text-align: center;
    padding: 1rem 0;
    font-family: 'Mustard';
    font-weight: 700;
    font-size: 25px;
    line-height: 25px;
    color: #9e3fea;
}
@media screen and (min-width: 480px) {
    .payment .payment-page-flag {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    #hide-package {
        display: none !important;
    }
}

.back-btn {
    position: absolute;
    z-index: 9;
    left: 0;
}
.back-btn button {
    padding: 0.75rem 1.5rem;
    font-weight: 600;
}
