#AssessmentPage {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

#AssessmentPage .background {
    z-index: -1;
    position: absolute;
}
#AssessmentPage .background img {
    position: relative;
    width: calc(100vw - 50px);
    height: calc(100vh - 50px);
    -o-object-fit: cover;
    object-fit: cover;
    filter: blur(50px);
}
#AssessmentPage .AssessmentContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 80%;
    background: linear-gradient(
        85.52deg,
        rgba(239, 239, 239, 0.9) -13.19%,
        rgba(255, 255, 255, 0.08) 103.43%
    );
    -webkit-backdrop-filter: blur(85px);
    backdrop-filter: blur(85px);
    border-radius: 27.6895px;
}
.AssessmentContainer .asset-5 {
    top: 0;
    left: 6%;
    position: absolute;
    width: 20%;
    z-index: -1;
}
.AssessmentContainer .asset-3 {
    top: 99%;
    left: 6%;
    position: absolute;
    width: 8%;
}
.AssessmentContainer .asset-6 {
    bottom: 10%;
    right: 8%;
    position: absolute;
    width: 5%;
}

#AssessmentPage .questionnaire {
    width: 80%;
    padding: 0 2rem;
}
#AssessmentPage .questionnaire h2 {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    color: #000;
}
#AssessmentPage .questionnaire-answer {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 800;
}
#AssessmentPage .questionnaire-answer input {
    display: none;
}
#AssessmentPage .questionnaire-answer .notfill {
    margin: 0.5rem 0;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    padding: 0.75rem;
    width: 240%;
    text-align: center;
    cursor: pointer;
}
/* #AssessmentPage .questionnaire-answer label:hover {
  background: rgba(155, 63, 231, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 20px;
} */
#AssessmentPage .questionnaire-answer-container {
    width: 80%;
    margin: auto;
    padding-top: 2rem;
    display: flex;
    justify-content: space-between;
    position: relative;
}
#AssessmentPage .questionnaire-answer-container img {
    cursor: pointer;
}
#AssessmentPage .questionnaire-count {
    padding-top: 3rem;
    margin-bottom: -3rem;
}
#AssessmentPage .questionnaire-count .submit {
    margin-left: 45%;
    margin-top: 10%;
    padding: 8px;
    border-radius: 5px;
    background-color: #ad5dee;
}
#AssessmentPage .questionnaire-count .submit:hover {
    background-color: #9b3fe7;
    color: #fff;
}
#AssessmentPage .questionnaire-count h3 {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #9b3fe7;
}

.fill {
    background-color: #9b3fe7;
    margin: 0.5rem 0;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    padding: 0.75rem;
    width: 240%;
    text-align: center;
    cursor: pointer;
}
.container {
    max-width: 1920px;
    width: 100%;
    
    margin: auto;
    overflow: hidden;
}

@media only screen and (max-width: 600px) {
    #AssessmentPage .background img {
        display: none;
    }
    #AssessmentPage .AssessmentContainer {
        background: #fff;
        /* border: 2px solid #000; */
        width: 90%;
        box-shadow: 1px 1px 5px #000;
    }
    #AssessmentPage .questionnaire-count .submit {
        margin-left: 37%;
    }

    #AssessmentPage .questionnaire h2 {
        font-size: 25px;
        line-height: 30px;
    }
    .AssessmentContainer .asset-5 {
        width: 40%;
    }
    .AssessmentContainer .asset-3 {
        width: 15%;
    }
    .AssessmentContainer .asset-6 {
        width: 15%;
    }
    #AssessmentPage .questionnaire-answer .notfill,
    .fill {
        width: 100%;
        padding: 0.65rem;
    }

    #AssessmentPage .questionnaire-answer-container img {
        margin: 0px -40px;
        width: 30px;
    }
}

#disable {
    filter: grayscale(100%);
}
