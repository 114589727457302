.loginContainer {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 391px;
    margin: auto;
    background: linear-gradient(
        85.52deg,
        rgba(239, 239, 239, 0.9) -28.09%,
        #ffffff14 88.52%
    );
}

.login-container .loginForm {
    height: 100vh;
}
.login-container .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.login-container .login-form h2 {
    font-family: 'Angkor', cursive;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #9e3fea;
    padding-bottom: 2rem;
}
.login-container .login-form p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    width: 90%;
    color: #000;
}
.login-container .login-form .form {
    margin-top: 2rem;
    box-shadow: 0px 4px 10px rgba(51, 51, 51, 0.15);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.login-container .login-form .form h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: -0.015em;
    color: #fff;
    background: #9e3fea;
    padding: 0.5rem 1rem;
    border-radius: 50px 0 0 50px;
}
.login-container .login-form .form input {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.1em;
    width: 175px;
    border-radius: 0 50px 50px 0;
}
.login-container .login-form button {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
}

.btn-primary {
    background: #9e3fea;
    border: 2px solid #9e3fea;
    color: white;
    padding: 0.5rem 1.5rem;
    border-radius: 20px;
    width: 200px;
    margin-top: 4rem;
}
.btn-secondary {
    margin-top: 2rem;
    color: #9e3fea;
    border: 2px solid #9e3fea;
    width: 200px;
    padding: 0.5rem 1.5rem;
    border-radius: 20px;
    background: transparent;
}

.login-container .login-form button:hover {
    border-bottom: 2px solid #9e3fea;
}
.login-container .login-form .otp-form {
    margin-top: 2rem;
}
.login-container .login-form .otp-form input {
    padding: 0.75rem 1rem;
    width: 15px;
    background: transparent;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    border-bottom: 3px solid #9e3fea;
    margin: 0 0.5rem;
}
.otp-form {
    display: flex;
}
.otp-form input {
    border-radius: 0px !important;
    font-weight: 600;
    font-size: 1.2rem !important;
    border: none !important;
    border-bottom: 4px solid #9e3fea !important;
    background: rgba(0, 0, 0, 0) !important;
    margin: 0 5px;
    padding: 0;
    text-align: center;
    padding-bottom: 1rem;
}
